import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  IconButton,
  Pagination,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import FullScreenDialog from "components/atoms/FullScreenDialog";
import { Close } from "@mui/icons-material";
import CreateTemplatePreview from "./CreateTemplatePreview";
import {
  CreateExcelTemplate,
  TemplateExcelDataInfo,
  TemplateExcel,
} from "services/models";
import ModalController from "@shared-components/modal/ModalController";
import {
  EXCEL_DATE_FORMAT,
  EXCEL_ROUND,
  EXCEL_TEMPLATE_INPUT,
  EXCEL_TEMPLATE_MASTER_LIST,
  TEMPLATE_EDIT_TYPE,
} from "@shared-constants";
import CreateTemplateNavi from "./CreateTemplateNavi";
import CreateTemplateInput from "./CreateTemplateInput";
import messages from "config/messages";
import EditTemplate from "screens/EditTemplate";
import { createObjectURL, getHtmlToPath, sort } from "@utils/index";
import { DropResult } from "react-beautiful-dnd";
import _ from "lodash";
import useDeepCompareEffect from "shared/hook/useDeepCompareEffect";
import { getPageImagePreviewHtml } from "@utils/template/excelTemplate";
import { uploadImageToS3 } from "@utils/template";

interface IProps {
  open: boolean;
  setOpen: Function;
  data: Array<TemplateExcelDataInfo>;
  htmlData: Array<CreateExcelTemplate>;
  tableRowsHeight: Array<string>;
  htmlString: string;
  onSave: (data: Array<TemplateExcelDataInfo>) => void;
  excelForm: TemplateExcel;
  listInputName?: Array<string>;
  clearStateForm?: boolean;
  activityBaseId: string;
  extraPage: Array<{
    tableRowsHeight: Array<string>;
    htmlString: string;
    listInputName: Array<string>;
    data: Array<TemplateExcelDataInfo>;
    htmlData: Array<CreateExcelTemplate>;
  }>;
}

export const initialForm: TemplateExcelDataInfo = {
  coordinate: "",
  name: "",
  input: EXCEL_TEMPLATE_INPUT.TEXT,
  maxlen: "",
  master: Object.keys(EXCEL_TEMPLATE_MASTER_LIST)[0],
  default: "",
  required: false,
  fontSize: "11",
  fontColor: "black",
  multiline: false,
  lineRows: 1,
  approval_step: 1,
  dateFormat: EXCEL_DATE_FORMAT[0].value, // 西暦
  calcRound: EXCEL_ROUND[0].value, // 四捨五入
  calcRoundPlace: 0,
};

const CreateTemplate = (
  {
    open,
    setOpen,
    data,
    htmlData,
    tableRowsHeight,
    htmlString,
    onSave,
    excelForm,
    listInputName = [],
    clearStateForm = false,
    activityBaseId,
    extraPage,
  }: IProps,
  ref: any,
) => {
  const [allStateForm, setAllStateForm] = useState<
    {
      stateForm: TemplateExcelDataInfo[];
      html_string: string;
      templatePath: string;
    }[]
  >([]);
  const [allOriginData, setAllOriginData] = useState<
    {
      stateForm: TemplateExcelDataInfo[];
      html_string: string;
      templatePath: string;
    }[]
  >([]);
  const [stateForm, setStateForm] = useState<Array<TemplateExcelDataInfo>>([]);
  const [originData, setOriginData] = useState<Array<TemplateExcelDataInfo>>(
    [],
  );
  const [html_string, setHtmlString] = useState("");
  const [coordinate, setCoordinate] = useState("");
  const [imgPath, setImgPath] = useState<string>();
  const [imagePage, setImagePage] = useState(false);
  const [openItemNavi, setOpenItemNavi] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [inputInfo, setInputInfo] = useState<TemplateExcelDataInfo>();
  const [isUpdate, setIsUpdate] = useState(false);
  const [templatePath, setTemplatePath] = useState("");
  const [editTemplateState, setEditTemplateState] = useState<{
    [key: string]: string;
  }>({});
  const [page, setPage] = useState(0);
  const refInput = useRef<any>();
  const refNavi = useRef<any>();
  const extraPageInfo = useMemo(() => {
    return allStateForm
      .filter((_, i) => i > 0)
      .map((value) => ({
        template_path: value.templatePath,
        data_info: value.stateForm,
      }));
  }, [allStateForm]);

  useImperativeHandle(ref, () => ({
    stateForm: stateForm,
    allStateForm: allStateForm.map((value) => value.stateForm),
  }));

  useDeepCompareEffect(() => {
    setStateForm(data);
    setOriginData(data);
    let newAllStateForm: {
      stateForm: TemplateExcelDataInfo[];
      html_string: string;
      templatePath: string;
    }[] = [];
    newAllStateForm.push({
      stateForm: data,
      html_string: "",
      templatePath: "",
    });
    extraPage.forEach((value) => {
      newAllStateForm.push({
        stateForm: value.data,
        html_string: value.htmlString,
        templatePath: "",
      });
    });
    setAllStateForm(newAllStateForm);
    setAllOriginData(newAllStateForm);
  }, [data, extraPage]);

  useEffect(() => {
    if (open) {
      setImagePage(false);
      setPage(0);
      if (stateForm.length > 0) {
        setOriginData([...stateForm]);
        setCoordinate(stateForm[0].coordinate);
        setInputInfo({ ...stateForm[0] });
        setIsUpdate(true);
      } else {
        setClear();
      }
    }
  }, [open]);

  useEffect(() => {
    if (html_string !== "" || allStateForm.length === 0) {
      return;
    }
    if (htmlString.length > 0) {
      // プレビューHTML、画像取得
      const blob = new Blob([htmlString], { type: "text/html" });
      const path = createObjectURL(blob);
      setTemplatePath(path);

      let width, height;
      if (excelForm.is_landscape) {
        width = 1090;
        height = 670;
      } else {
        width = 780;
        height = 1090;
      }

      getHtmlToPath(
        getPageImagePreviewHtml(htmlString),
        width,
        height,
        "png",
      ).then((res) => {
        setImgPath(res);
      });

      const cloneAllStateForm = _.cloneDeep(allStateForm);
      cloneAllStateForm.forEach((value, index) => {
        if (index === 0) {
          value.html_string = htmlString;
          value.templatePath = path;
        } else {
          const blob = new Blob([value.html_string], { type: "text/html" });
          const path = createObjectURL(blob);
          value.templatePath = path;
        }
      });
      setAllStateForm(cloneAllStateForm);
    } else {
      setTemplatePath("");
    }

    setHtmlString(htmlString);
    if (clearStateForm) setClear();
  }, [htmlString, html_string, clearStateForm, allStateForm]);

  const setClear = () => {
    setStateForm([]);
    setOriginData([]);
    setCoordinate("");
    setInputInfo(undefined);
    setIsUpdate(false);
    setEditTemplateState({});
    setAllStateForm((prev) =>
      prev.map((value) => ({ ...value, stateForm: [] })),
    );
    setAllOriginData((prev) =>
      prev.map((value) => ({ ...value, stateForm: [] })),
    );
  };

  // 入力制御 ------------------------------------------------
  const changeInputInfo = (select_coordinate: string, name?: string) => {
    let index = stateForm.findIndex(
      (item) => item.coordinate === select_coordinate,
    );
    if (index < 0) {
      setInputInfo({
        ...initialForm,
        coordinate: select_coordinate,
        name: name ?? select_coordinate,
      });
      setIsUpdate(false);
    } else {
      setInputInfo({ ...stateForm[index] });
      setIsUpdate(true);
    }

    setCoordinate(select_coordinate);
  };

  // 更新確認 ------------------------------------------------
  const confirmUpdate = (afterDialogClose?: Function) => {
    ModalController.show({
      message: messages.COMMON.MSG_CONFIRM_GO_BACK,
      visibleButton1: true,
      visibleButton2: true,
      handlePressButton1: () => {
        if (typeof afterDialogClose == "function") afterDialogClose();
      },
      handlePressButton2: () => {
        const res = updateStateForm();
        if (res && typeof afterDialogClose == "function") afterDialogClose();
      },
      button1: { title: messages.COMMON.BUTTON.CANCEL },
    });
  };

  // 保存確認 ------------------------------------------------
  const hasUpdateItems = () => {
    return stateForm && originData && !_.isEqual(stateForm, originData);
  };

  const confirmSave = () => {
    if (hasUpdateData() || hasUpdateItems()) {
      ModalController.show({
        message: messages.COMMON.MSG_CONFIRM_GO_BACK,
        visibleButton1: true,
        visibleButton2: true,
        handlePressButton1: () => {
          setStateForm([...originData]);
          handleClose();
        },
        handlePressButton2: () => handleSave(),
        button1: { title: messages.COMMON.BUTTON.CANCEL },
      });
      return;
    }
    handleClose();
  };

  // プレビュー ------------------------------------------------
  const handleClick = (select_coordinate: string, cell_value: string) => {
    if (select_coordinate === coordinate) return;

    if (cell_value.length > 0) {
      ModalController.show({
        message: "既に文字が入力されています",
        visibleButton2: true,
      });
      return;
    }
    if (hasUpdateData()) {
      // 保存確認
      confirmUpdate(() => changeInputInfo(select_coordinate));
      return;
    }
    changeInputInfo(select_coordinate);
  };

  // 入力内容 ------------------------------------------------
  const handleSelectNavi = (select_coordinate: string) => {
    if (select_coordinate === coordinate) return;
    if (hasUpdateData()) {
      // 保存確認
      confirmUpdate(() => changeInputInfo(select_coordinate));
      return;
    }
    changeInputInfo(select_coordinate);
  };

  const handleDelete = (select_coordinate: string) => {
    let newStateForm = [
      ...stateForm.filter((item) => item.coordinate !== select_coordinate),
    ];
    // 入力内容の選択を変更
    if (inputInfo?.coordinate === select_coordinate) {
      setInputInfo(newStateForm.length > 0 ? newStateForm[0] : undefined);
      setCoordinate(newStateForm.length > 0 ? newStateForm[0].coordinate : "");
    }
    //　削除した項目が、添付画像ページで選択されていれば設定を解除する
    const image_page_input = newStateForm.find(
      (item) => item.input === EXCEL_TEMPLATE_INPUT.IMAGE_PAGE,
    );
    if (image_page_input) {
      [...Array(6)].forEach((_, i) => {
        var tmp_field: any = `headImage${i + 1}`;
        var field: keyof TemplateExcelDataInfo = tmp_field;
        if (image_page_input[field] === select_coordinate) {
          image_page_input[field] = "" as never;
        }
      });
      if (coordinate == "image_page") {
        setInputInfo(image_page_input);
      }
    }
    // 削除した項目が、電子印で選択されていれば設定を解除する
    const seal_input = newStateForm.filter(
      (item) =>
        item.input === EXCEL_TEMPLATE_INPUT.USER_SEAL &&
        item.default === select_coordinate,
    );
    if (seal_input) {
      seal_input.forEach((v) => {
        v.default = "login";
        if (coordinate == v.coordinate) {
          setInputInfo(v);
        }
      });
    }
    // 削除した項目のマスタ追加項目が入力方法に選択されていれば、設定を解除する
    const custom_item = newStateForm.filter(
      (item) => select_coordinate == item.master_coordinate,
    );
    if (custom_item) {
      custom_item.forEach((v) => {
        v.input = EXCEL_TEMPLATE_INPUT.TEXT;
        v.maxlen = "";
        v.multiline = false;
        v.lineRows = 1;
        v.master_coordinate = undefined;
        v.master_attr = undefined;
        if (coordinate == v.coordinate) {
          setInputInfo(v);
        }
      });
    }
    setStateForm(newStateForm);
    if (select_coordinate === "image_page") setImagePage(false);

    // 入力画面プレビューの入力値を削除
    if (
      Object.keys(editTemplateState).find((key) => key === select_coordinate)
    ) {
      delete editTemplateState[select_coordinate];
    }
  };

  const handleDnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const sorted = sort(
      stateForm,
      result.source.index,
      result.destination.index,
    );
    setStateForm(sorted);
  };

  // 更新 ------------------------------------------------
  const handleAddItem = () => {
    if (!inputInfo) {
      return;
    }
    const res = updateStateForm();
    if (res) {
      setInputInfo({ ...refInput.current.stateForm });
      setIsUpdate(true);
    }
  };

  const hasUpdateData = () => {
    return refInput.current && refInput.current.hasUpdateData();
  };

  const updateStateForm = (
    update_coordinate?: string,
    newStateForm?: TemplateExcelDataInfo[],
  ) => {
    if (!refInput.current || !refInput.current.stateForm) return false;

    // 入力チェック
    if (!refInput.current.validationAllCheck()) {
      ModalController.hide();
      setTimeout(() => {
        ModalController.show({
          message: "入力内容にエラーがあります",
          visibleButton2: true,
        });
      }, 100);
      return false;
    }

    // 入力内容を更新
    if (!update_coordinate) update_coordinate = coordinate;
    if (!newStateForm) newStateForm = [...stateForm];
    let index = stateForm.findIndex(
      (item) => item.coordinate === update_coordinate,
    );
    if (index < 0) {
      // 追加
      newStateForm.push(refInput.current.stateForm);
    } else {
      // 置換
      newStateForm.splice(index, 1, refInput.current.stateForm);
    }
    // 変更した項目のマスタ追加項目が入力方法に選択されていれば、設定を解除する
    const custom_item = newStateForm.filter(
      (item) => update_coordinate == item.master_coordinate,
    );
    if (custom_item) {
      custom_item.forEach((v) => {
        v.input = EXCEL_TEMPLATE_INPUT.TEXT;
        v.maxlen = "";
        v.multiline = false;
        v.lineRows = 1;
        v.master_coordinate = undefined;
        v.master_attr = undefined;
      });
    }
    setStateForm(newStateForm);
    updateAllStateForm(newStateForm);
    return newStateForm;
  };

  const updateAllStateForm = (state_form = stateForm) => {
    let cloneAllStateForm = _.cloneDeep(allStateForm);
    cloneAllStateForm[page]["stateForm"] = _.cloneDeep(state_form);
    setAllStateForm(cloneAllStateForm);
  };

  const updateImagePage = (add_page: boolean) => {
    if (add_page) {
      const image_page = stateForm.find(
        (item) => item.coordinate === "image_page",
      );
      if (!image_page) {
        let newStateForm = [...stateForm];
        newStateForm.push({
          ...initialForm,
          coordinate: "image_page",
          name: "添付画像ページ",
          input: EXCEL_TEMPLATE_INPUT.IMAGE_PAGE,
          titleImage: "",
          headImage1: "",
          headImage2: "",
          headImage3: "",
          headImage4: "",
          headImage5: "",
          headImage6: "",
        });
        setStateForm(newStateForm);
        setIsUpdate(false);
      }
    }
  };

  // 入力画面プレビュー ------------------------------------------------
  const handleOpenPreview = () => {
    if (hasUpdateData()) {
      // 保存確認
      confirmUpdate(() => {
        setInputInfo({ ...refInput.current.stateForm });
        setIsUpdate(true);
        setOpenTemplate(true);
      });
      return;
    }
    if (refNavi.current && refNavi.current.hasUpdateData()) {
      // 入力項目の順番・タブに変更があれば内容を更新
      updateAllStateForm();
    }
    setOpenTemplate(true);
  };

  // 保存 ------------------------------------------------
  const checkSave = async () => {
    // 空のカテゴリーの有無をチェック
    if (refNavi.current) {
      if (refNavi.current.hasEmptyCategories()) {
        ModalController.show({
          message: messages.TEMPLATE.MSG_CONFIRM_EMPTY_TAB,
          visibleButton1: true,
          visibleButton2: true,
          handlePressButton2: async () => await handleSave(),
        });
        return;
      }
    }
    await handleSave();
  };

  const handleSave = async () => {
    // 画像設定の画像をアップロード
    let newStateForm = _.cloneDeep(allStateForm);
    if (hasUpdateData()) {
      console.log("hewe");
      const res = updateStateForm();
      if (res) newStateForm[page]["stateForm"] = _.cloneDeep(res);
    }

    for (let n = 0; n < newStateForm.length; n++) {
      const state_form = newStateForm[n].stateForm;
      const draw_image_input = state_form.filter(
        (item) => item.input == EXCEL_TEMPLATE_INPUT.DRAW_IMAGE,
      );
      for (let index = 0; index < draw_image_input.length; index++) {
        const item = draw_image_input[index];
        if (!item.image) continue;
        const new_draw_image = await uploadImageToS3([item.image], Date.now());
        item.image = new_draw_image[0];
      }
    }

    setAllStateForm(newStateForm);

    setTimeout(() => {
      onSave([...newStateForm[page].stateForm]);
      handleClose();
    }, 500);
  };

  // 閉じる ------------------------------------------------
  const handleClose = () => setOpen(false);

  // ページ変更 ------------------------------------------------
  const handleChangePage = (page_num: number) => {
    if (hasUpdateData()) {
      // 保存確認
      confirmUpdate(() => {
        setIsUpdate(true);
        setPage(page_num);
      });
      return;
    }
    if (refNavi.current && refNavi.current.hasUpdateData()) {
      // 入力項目の順番・タブに変更があれば内容を更新
      updateAllStateForm();
    }
    setPage(page_num);
  };

  useEffect(() => {
    if (allStateForm.length > page) {
      setStateForm(_.cloneDeep(allStateForm[page].stateForm));
      setHtmlString(allStateForm[page].html_string);
      setTemplatePath(allStateForm[page].templatePath);
      if (allStateForm[page].stateForm.length > 0) {
        setCoordinate(allStateForm[page].stateForm[0].coordinate);
        setInputInfo({ ...allStateForm[page].stateForm[0] });
      } else {
        setCoordinate("");
        setInputInfo(undefined);
      }
    }
    if (refNavi.current) {
      // 項目一覧を初期化
      refNavi.current.clear();
    }
  }, [page]);

  return (
    <FullScreenDialog
      open={open}
      onClose={handleClose}
      title={"テンプレート設定"}
    >
      <Tooltip title="閉じる">
        <IconButton
          onClick={() => confirmSave()}
          sx={{
            position: { xs: "absolute", md: "fixed" },
            right: 20,
            top: 15,
            zIndex: 2000,
          }}
        >
          <Close sx={{ color: "white" }} />
        </IconButton>
      </Tooltip>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Typography variant="body2">
          テンプレートの入力箇所をクリックして内容を設定してください
        </Typography>
        <Pagination
          count={extraPage.length + 1}
          defaultPage={1}
          page={page + 1}
          siblingCount={0}
          onChange={(e, p) => {
            handleChangePage(p - 1);
          }}
          disabled={extraPage.length == 0}
          sx={{ ml: 1 }}
        />
        <Box flexGrow={1} />
        <FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={imagePage}
                onChange={(_, checked) => {
                  setImagePage(checked);
                  updateImagePage(checked);
                }}
              />
            }
            componentsProps={{ typography: { variant: "body2" } }}
            label="添付画像ページを表示"
          />
        </FormControl>
      </Box>
      <Stack direction={{ xs: "column", md: "row" }}>
        <Card
          sx={{
            height: "70vh",
            width: openItemNavi ? 250 : 40,
            minWidth: openItemNavi ? 250 : 40,
          }}
        >
          <CardContent sx={{ height: "calc(100% - 60px)" }}>
            <CreateTemplateNavi
              open={openItemNavi}
              handleMenu={() => setOpenItemNavi(!openItemNavi)}
              stateForm={stateForm}
              coordinate={coordinate}
              handleSelect={handleSelectNavi}
              handleDelete={handleDelete}
              setStateForm={setStateForm}
              disableAddItem
              handleAddInput={(coordinate) => changeInputInfo(coordinate, "")}
              ref={refNavi}
            />
          </CardContent>
        </Card>

        <Card
          sx={{
            height: "70vh",
            width: 300,
            minWidth: 300,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
            <CreateTemplateInput
              data={inputInfo}
              excelTemplate={stateForm}
              isLandscape={excelForm.is_landscape}
              listInputName={
                page == 0 ? listInputName : extraPage[page - 1].listInputName
              }
              activityBaseId={activityBaseId}
              ref={refInput}
            />
          </CardContent>
          <CardActions sx={{ justifyContent: "center" }}>
            <Button
              variant="text"
              onClick={handleAddItem}
              disabled={!inputInfo}
            >
              {isUpdate ? "変更" : "追加"}
            </Button>
          </CardActions>
        </Card>

        <Card sx={{ flexGrow: 1, height: "70vh", display: "flex" }}>
          <CardContent sx={{ overflow: "auto", width: "100%" }}>
            {imagePage ? (
              <img
                src={imgPath}
                style={{ objectFit: "contain", width: "100%" }}
              />
            ) : (
              <CreateTemplatePreview
                data={page == 0 ? htmlData : extraPage[page - 1].htmlData}
                tableRowsHeight={
                  page == 0
                    ? tableRowsHeight
                    : extraPage[page - 1].tableRowsHeight
                }
                coordinate={coordinate}
                handleClick={handleClick}
              />
            )}
          </CardContent>
        </Card>
      </Stack>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
        <Button onClick={handleOpenPreview} sx={{ minWidth: 160 }}>
          入力画面プレビュー
        </Button>
        <Button onClick={checkSave} color="secondary" sx={{ minWidth: 160 }}>
          保存
        </Button>
      </Box>
      <EditTemplate
        open={openTemplate}
        setOpen={setOpenTemplate}
        type={TEMPLATE_EDIT_TYPE.TEMPLATE_EXCEL_REPORT_SETTING}
        infoToEdit={editTemplateState}
        template_path={
          allStateForm && allStateForm.length > 0
            ? allStateForm[0].templatePath
            : templatePath
        }
        onHandleTemplate={(newTemplate) => {
          setEditTemplateState((prev) => ({
            ...prev,
            ...newTemplate.templateEditInfo,
          }));
        }}
        data_info={
          allStateForm && allStateForm.length > 0
            ? allStateForm[0].stateForm
            : stateForm
        }
        is_landscape={excelForm.is_landscape}
        activityBaseId={activityBaseId}
        step={0}
        isMultiple={excelForm.is_multiple}
        extraPage={extraPageInfo}
      />
    </FullScreenDialog>
  );
};
export default forwardRef(CreateTemplate);
